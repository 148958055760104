import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from './router/index.js'
import aframe from 'aframe';
import io from 'socket.io-client';

const app = createApp(App)
app.config.globalProperties.$socketio = io();
app.use(router)
app.use(aframe);
app.mount('#app')