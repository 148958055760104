<template>
  <a-scene>

    <a-assets  >
      <img crossorigin="anonymous" id="skyTexture"
           src="/assets/bg.jpg">
    </a-assets>

    <a-camera>
      <a-entity id="marker" position="0 0 -5"></a-entity>
    </a-camera>



    <a-plane id="window"
             position="0 0 -4"
             rotation="0 0 180"
             width="7"
             height="3"
             color="black"
             material="opacity: 0.4; transparent: true"
    >
      <a-entity id="finish" visible="false" gltf-model="url(/assets/finish.glb)" position="0 -1 0" scale="1 1 1"
      />

      <a-plane id="stoplicht"
               position="-1 0 -1 "
               rotation="0 0 0"
               width="1"
               height="2.5"
               color="black"
               visible="false"
      >
        <a-cylinder position="0 -0.9 0" material="opacity: 0.4; transparent: true" rotation="90 0 0" height="0.1"
                    radius="0.15" color="green"></a-cylinder>
        <a-cylinder position="0 0 0" material="opacity: 0.4; transparent: true" rotation="90 0 0" height="0.1"
                    radius="0.15" color="orange"></a-cylinder>
        <a-cylinder position="0 0.9 0" rotation="90 0 0" height="0.1" radius="0.25" color="red"></a-cylinder>
      </a-plane>

      <a-entity gltf-model="url(/assets/steer.gltf)" scale="0.5 0.5" position="-2 -1.2 0" rotation="0 -20 -30"/>

    </a-plane>

    <a-entity id="car" visible="false" gltf-model="url(/assets/car.gltf)" scale="0.2 0.2 0.2"
              animation="property: position; dur: 2000; easing: linear; loop: 4; autoplay:false;startEvents: start"
    />
    <a-entity id="plane" visible="false" gltf-model="url(/assets/jet/Jet.gltf)" scale="0.2 0.2 0.2"
              animation="property: position; dur: 2000; easing: linear; loop: 4; autoplay:false;startEvents: start"
    />
    <a-entity id="dir" visible="false" gltf-model="url(/assets/dir.glb)"
              scale="0.3 0.3 0.3"
              rotation="0 0 130"
              position="-1 1 -3" />
    <a-entity id="turn" visible="false" gltf-model="url(/assets/turn.glb)"
              scale="0.3 0.3 0.3"
              rotation="0 0 0"
              position="-1 1 -3" />
    <a-entity id="stp" visible="false" gltf-model="url(/assets/stop.gltf)" scale="1 1 1" position="1 1 -3" />


    <a-sky src="#skyTexture"></a-sky>


  </a-scene>
</template>

<script>
import {useWakeLock} from '@vueuse/core'
import {computed, reactive} from "vue";

export default {
  name: "sint",

  setup() {
  },
  data() {
    return {
      cnt: 0,
      name: "",
      timer: null
    };
  },
  // computed: {
  //   currentPosition() {
  //       return "2 1.25 -2";
  //   }
  // },
  mounted() {
    // let script1 = document.createElement('script')
    // script1.setAttribute('src', 'js/aframe-environment-component.js')
    // document.head.appendChild(script1)
    // let script2  = document.createElement('script')
    // script2.setAttribute('src', 'js/aframe-spe-particles-component.js')
    // document.head.appendChild(script2)
    // AFRAME.registerComponent('foo', {
    //   tick: function () {
    //     console.log(123)
    //   }
    // })
    // AFRAME.registerComponent('rotation-reader', {
    //   tick: function () {
    //     // `this.el` is the element.
    //     // `object3D` is the three.js object.
    //     console.log(1111)
    //
    //     // `rotation` is a three.js Euler using radians. `quaternion` also available.
    //     console.log(this.el.object3D.rotation);
    //
    //     // `position` is a three.js Vector3.
    //     console.log(this.el.object3D.position);
    //   }
    // });
    this.timer = setInterval(() => {
      this.focus()
    }, 100)
    this.$socketio.on("sint", (name) => {
      console.log("Incoming socket.io" + name)
      this.hit(name)
    });
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    hit(name) {
      if ( this.cnt > 0 ) return;
      this.name = name;
      console.log('---hit='+name)
      let markerEl = document.querySelector('#marker');
      const realWorldPosition = markerEl.object3D.getWorldPosition(new THREE.Vector3())
      const realWorldRotation = document.querySelector('a-scene').camera.el.getAttribute('rotation')
      console.log(realWorldRotation)
      // console.log(markerEl.getAttribute('rotation'))

      if (name==="stoplicht") {
        this.cnt = 30
        let t = document.querySelector('#'+name);
        // t.setAttribute('position', realWorldPosition);
        t.setAttribute("visible", "true");
      }
      if (name==="left") {
        this.cnt = 30
        this.name='dir'
        let t = document.querySelector('#'+this.name);
        t.setAttribute('position', realWorldPosition);
        realWorldRotation.z = -50
        t.setAttribute('rotation', realWorldRotation);
        // t.setAttribute('rotation', realWorldPosition);
        t.setAttribute("visible", "true");
      }
      if (name==="right") {
        this.cnt = 30
        this.name='dir'
        let t = document.querySelector('#'+this.name);
        t.setAttribute('position', realWorldPosition);
        realWorldRotation.z = 130
        t.setAttribute('rotation', realWorldRotation);
        // t.setAttribute('rotation', realWorldPosition);
        t.setAttribute("visible", "true");
      }
      if (name==="straight") {
        this.cnt = 30
        this.name='dir'
        let t = document.querySelector('#'+this.name);
        t.setAttribute('position', realWorldPosition);
        realWorldRotation.z = 220
        t.setAttribute('rotation', realWorldRotation);
        // t.setAttribute('rotation', realWorldPosition);
        t.setAttribute("visible", "true");
      }
      if (name==="turn") {
        this.cnt = 30
        this.name='turn'
        let t = document.querySelector('#'+this.name);
        t.setAttribute('position', realWorldPosition);
        t.setAttribute('rotation', realWorldRotation);
        // t.setAttribute('rotation', realWorldPosition);
        t.setAttribute("visible", "true");
      }
      if (name==="car") {
        let t = document.querySelector('#car');
        t.addEventListener('animationcomplete', function(){
          t.setAttribute("visible","false")
        });
        t.setAttribute("visible","true")
        t.setAttribute('animation', 'to: '+(realWorldPosition.x-4)+' '+(realWorldPosition.y)+' '+(realWorldPosition.z));
        t.setAttribute('animation', 'from: '+(realWorldPosition.x+4)+' '+(realWorldPosition.y)+' '+(realWorldPosition.z));
        t.emit(`start`, null, false);
      }
      if (name==="plane") {
        let t = document.querySelector('#plane');
        t.addEventListener('animationcomplete', function(){
          t.setAttribute("visible","false")
        });
        t.setAttribute("visible","true")
        t.setAttribute('animation', 'to: '+(realWorldPosition.x-4)+' '+(realWorldPosition.y+2)+' '+(realWorldPosition.z));
        t.setAttribute('animation', 'from: '+(realWorldPosition.x+4)+' '+(realWorldPosition.y+2)+' '+(realWorldPosition.z));
        t.emit(`start`, null, false);
      }
      if (name==="finish") {
        this.cnt = 100
        let t = document.querySelector('#'+name);
        // t.setAttribute('position', realWorldPosition);
        t.setAttribute("visible", "true");
      }

      // back:40
      // front: 220
      // left: -50
      // right: 130
    },
    focus() {
      if (this.cnt > 0 ) {
        this.cnt--;
        if ( this.cnt === 0 ) {
          let t = document.querySelector('#'+this.name);
          t.setAttribute("visible", "false");
        }
      }

      let markerEl = document.querySelector('#marker');
      const realWorldPosition = markerEl.object3D.getWorldPosition(new THREE.Vector3())
      const realWorldRotation = document.querySelector('a-scene').camera.el.getAttribute('rotation')

      let t = document.querySelector('#window');
      t.setAttribute('position', realWorldPosition);
      t.setAttribute('rotation', {x: realWorldRotation.x, y: realWorldRotation.y});

    }
  }
};
</script>

<style>

</style>
